import { useState } from "react";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
//   Polyline,
//   DirectionsRenderer,
// } from "react-google-maps";
// import { Polygon } from "react-google-maps";
import { robotIcon } from "./robotIcon";
import MedianIncomeKml from "./testmap.kml";
// import { KmlLayer } from "react-google-maps";
// import ReactGoogleMapLoader from "react-google-maps-loader";
// import { DistanceMatrixService } from "@react-google-maps/api";
import * as React from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRobotDirectionbyMapbox } from "../redux/actions";
import { robotConstants } from "../redux/constants";
import axios from "axios";
// let directions = {};
// let directions = JSON.parse(localStorage.getItem("directionRoutes"))
//   ? JSON.parse(localStorage.getItem("directionRoutes"))
//   : {};
var image = {};
let origin;
let destination;
let fivesec = true;
let kmlurl = localStorage.getItem("kmlurl");
mapboxgl.accessToken =
  "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

const options = {
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "#003300",
  strokeOpacity: 1,
  strokeWeight: 4,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
const optionsteleop = {
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "rgb(220,53,69)",
  strokeOpacity: 1,
  strokeWeight: 4,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
const optionsteleoptwo = {
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "#7859bc",
  strokeOpacity: 1,
  strokeWeight: 4,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const robotpathoptions = {
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "rgb(113, 207, 98)",
  strokeOpacity: 1,
  strokeWeight: 8,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const checkActionId = (commandListData, props) => {
  // console.log(direction);
  // console.log(commandListData);
  if (
    !localStorage.getItem("actionId") ||
    localStorage.getItem("actionId") === "null"
  ) {
    // console.log("FIRST SAVE");
    localStorage.setItem(
      "actionId",
      JSON.stringify({
        actionType: commandListData.actionType,
        actionId: commandListData.actionId,
      })
    );
    // console.log("First ", localStorage.getItem("actionId"));
    return true;
  } else {
    if (
      commandListData.actionId !==
      JSON.parse(localStorage.getItem("actionId")).actionId
    ) {
      // console.log(direction);
      // console.log(commandListData.actionId);
      // console.log("ACTION ID NOT EQUAL");
      localStorage.setItem(
        "actionId",
        JSON.stringify({
          actionType: commandListData.actionType,
          actionId: commandListData.actionId,
        })
      );
      // console.log("Second ", localStorage.getItem("actionId"));
      return true;
    } else {
      // props.setToggleDirection(false);
      // console.log(setToggleDirection(false));
      // console.log("ACTION ID SAME");
      return false;
    }
  }
};
const Marker = ({ onClick, children, feature }) => {
  const _onClick = () => {
    onClick(feature.properties.description);
  };
  return createPortal(
    <button onClick={_onClick} className="marker">
      {children}
    </button>,
    document.body
  );
};
const MapboxMain = (props) => {
  // console.log("loop?")
  const dispatch = useDispatch();
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const [robotMarker, setRobotMarker] = useState(null);
  const [destinationMarker, setDestinationMarker] = useState(null);

  const [polygonarray, setPolygonarray] = useState([]);
  const [nogozonescoordinates, setnogozonescoordinates] = useState(
    props.nogozones.map((data) => [data.lng, data.lat])
  );
  const [geofencecoordinates, setgeofencecoordinates] = useState(
    props.geofencezone.map((data) => [data.lng, data.lat])
  );
  const [robotPathLayer, setRobotPathLayer] = useState([[0, 0]]);
  const [mapboxPath, setMapboxPath] = useState([[0, 0]]);
  const [lng, setLng] = useState(
    robotDetails[props.robotId] && robotDetails[props.robotId].longitude
  );
  const [lat, setLat] = useState(
    robotDetails[props.robotId] && robotDetails[props.robotId].latitude
  );
  // useEffect(()=>{
  //   setLat(robotDetails[props.robotId] && robotDetails[props.robotId].latitude)
  //   setLng(robotDetails[props.robotId] && robotDetails[props.robotId].longitude)
  //   // new mapboxgl.Marker(robotMarkerDiv)
  //   // .setLngLat([
  //   //   lng,
  //   //   lat,
  //   // ])
  //   // .addTo(map.current);
  // },[robotDetails[props.robotId].latitude,robotDetails[props.robotId].longitude])
  // const mapboxCon =(
  //   dispatch,
  //   robotId,
  //   robotlatitude,
  //   robotlongitude,
  //   item,
  //   robotDetails,
  // ) =>  {

  //   if (robotDetails[robotId]?.robotCommandList?.length > 0) {

  //     origin = {
  //       lat: item.latitude,
  //       lng: item.longitude,
  //     };
  //     destination = {
  //       lat: robotlatitude,
  //       lng: robotlongitude,
  //     };

  //     dispatch(getRobotDirection(origin, destination))

  //   }
  //   return true
  // };
  const [zoom, setZoom] = useState(15);
  const { robotDirections } = useSelector(
    (state) => state.robotDirectionsReducer
  );

  const robotMarkerDiv = document.createElement("div");
  robotMarkerDiv.style.width = "35px";
  robotMarkerDiv.style.height = "35px";
  robotMarkerDiv.style.backgroundImage =
    "url(/assets/images/robot-icon-pickup_1.svg)";
  robotMarkerDiv.style.borderRadius = "100%";
  robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";

  const destinationMarkerDiv = document.createElement("div");
  destinationMarkerDiv.style.width = "35px";
  destinationMarkerDiv.style.height = "35px";
  destinationMarkerDiv.style.backgroundImage =
    "url(/assets/images/robot-icon-dropoff_1.svg)";
  destinationMarkerDiv.style.borderRadius = "100%";
  destinationMarkerDiv.style.animation = "example 1s ease-in-out infinite";
  const updateRobotpath = () => {
    const map = mapRef.current;
    if (!props.robotpathcheck) {
      if (robotDetails[props.robotId].path && map.isStyleLoaded()) {
        const newrobotPath = robotDetails[props.robotId].path.map(
          (pathDirection) => [pathDirection.lng, pathDirection.lat]
        );
        const mySource = map.getSource("robotPath");
        map &&
          mySource &&
          mySource.setData({
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          });
          map.getLayer("path")&&
          map.setPaintProperty("path","line-color","#0f0");
      }
    } else {
      if (
        robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[props.robotId].robotCommandList.length > 0 &&
        robotDetails[props.robotId].robotCommandList[0].actionType ===
          "MOVELOCATION" &&map.isStyleLoaded()&&
        checkActionId(robotDetails[props.robotId].robotCommandList[0], props)
      ) {
        // console.log("INSIDE IF IF")

        origin = {
          lat: robotDetails[props.robotId].robotCommandList[0].latitude,
          lng: robotDetails[props.robotId].robotCommandList[0].longitude,
        };
        destination = {
          lat: props.robotlatitude,
          lng: props.robotlongitude,
        };

        dispatch(getRobotDirectionbyMapbox(origin, destination));
      }
      // console.log("are we here?", JSON.parse(localStorage.getItem("directionRoutes")))

      // map.current.removeLayer("robotPathOutline");
      // map.current.removeSource("robotPath");
      // console.log("coordinates", mapboxPath)
      // console.log(robotPath)

      if (robotDirections.hasOwnProperty("routes")) {
        // console.log(props.robotpathcheck)

        const mapboxPath = robotDirections.routes[0].geometry.coordinates;
        const mySource = map.getSource("robotPath");
        map &&
        mySource &&
        mySource.setData({
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: mapboxPath,
          },
        });
        map.getLayer("path")&&
        map.setPaintProperty("path","line-color","rgba(0,51,0,0.5)");
      }
    }
  };
  useEffect(() => {
    // if (map.current) return; // initialize map only once

    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "map-container-id",
        style: "mapbox://styles/mapbox/streets-v12",
        center: [
          robotDetails[props.robotId].longitude,
          robotDetails[props.robotId].latitude,
        ],
        zoom: zoom,
      });

      // initialize robot marker
      const newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
        .setLngLat([
          robotDetails[props.robotId].longitude,
          robotDetails[props.robotId].latitude,
        ])
        .addTo(newMap);

      // initialize destination marker
      const newDestinationMarker = new mapboxgl.Marker(destinationMarkerDiv)
        .setLngLat([0, 0])
        .addTo(newMap);
      setDestinationMarker(newDestinationMarker);
      // initialize nogozone

      newMap.on("load", () => {
        // Add a data source containing GeoJSON data.
        newMap.addSource("nogozone", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates: [nogozonescoordinates],
            },
          },
        });
        newMap.addLayer({
          id: "nogozoneoutline",
          type: "line",
          source: "nogozone",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });
      // initialize geofencezone

      newMap.on("load", () => {
        // Add a data source containing GeoJSON data.
        newMap.addSource("geofence", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              // These coordinates outline Maine.
              coordinates: [geofencecoordinates],
            },
          },
        });
        newMap.addLayer({
          id: "geofenceoutline",
          type: "line",
          source: "geofence",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      });
      // initialize fleetzones
      let newpolygonarray = [];
      props.fleetzones.map((polygon, index) => {
        newpolygonarray.push(polygon.map((data) => [data.lng, data.lat]));
        newMap.on("load", () => {
          // Add a data source containing GeoJSON data.
          newMap.addSource("fleetzone-" + index, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                // These coordinates outline Maine.
                coordinates: [newpolygonarray[index]],
              },
            },
          });
          newMap.addLayer({
            id: "fleetzoneoutline-" + index,
            type: "line",
            source: "fleetzone-" + index,
            layout: {},
            paint: {
              "line-color": "#7859bc",
              "line-width": 4,
            },
          });
        });

        return newpolygonarray;
      });


      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });
      setPolygonarray(newpolygonarray);
      setRobotMarker(newRobotMarker);
      setMap(newMap);
      mapRef.current = newMap;

    };
    if (!map) {
      initializeMap();
    }


    return () => {
      if (map) {
        map.remove();
        setMap(null)
      }
    };
  }, [map, props.nogozones, props.fleetzones, props.geofencezone]);

  useEffect(() => {
    if(map&& map.isStyleLoaded()){
      // map.on("load", () => {
      // console.log(map)
      // console.log(map.getSource("robotPath"))
      // newMap.addSource("robotPath", {
      //   type: "geojson",
      //   data: {
      //     type: "Feature",
      //     geometry: {
      //       type: "LineString",
      //       // These coordinates outline Maine.
      //       coordinates: robotPath,
      //     },
      //   },
      // });
      // newMap.addLayer({
      //   id: "robotPathOutline",
      //   type: "line",
      //   source: "robotPath",
      //   layout: {
      //     "line-join": "round",
      //     "line-cap": "round",
      //   },
      //   paint: {
      //     "line-color": "#0f0",
      //     "line-width": 4,
      //   },
      // });
      // const updatePathOnMap = () => {
      if (!map.getSource("robotPath") &&
        robotDetails[props.robotId].hasOwnProperty("path") &&
        robotDetails[props.robotId].path.length > 0
      ) {
        const newrobotPath =
          robotDetails[props.robotId].path &&
          robotDetails[props.robotId].path.map((pathDirection) => [
            pathDirection.lng,
            pathDirection.lat,
          ]);
        // const robotGeojson = {
        //   type: "Feature",
        //   properties: {},
        //   geometry: {
        //     type: "LineString",
        //     coordinates: newrobotPath,
        //   },
        // };

        // if (newMap.getSource("robotPath")) {
        //   newMap.getSource("robotPath").setData(robotGeojson);
        // } else {
        map.addSource("robotPath", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: newrobotPath,
            },
          },
        });

        map.addLayer({
          id: "path",
          type: "line",
          source: "robotPath",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#0f0",
            "line-width": 4,
          },
        });
      }
      // };

      //   updatePathOnMap();
      // }
    // });
  }
    // move robot marker
    if (robotMarker) {
      robotMarker.setLngLat([
        robotDetails[props.robotId].longitude,
        robotDetails[props.robotId].latitude,
      ]);
    }

    // move destination marker
    if (
      destinationMarker &&
      robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
      robotDetails[props.robotId].robotCommandList.length > 0
    ) {
      // console.log(
      //   robotDetails[props.robotId].robotCommandList[0].longitude,
      //   robotDetails[props.robotId].robotCommandList[0].latitude,
      //   destinationMarker,
      //   robotDetails[props.robotId].hasOwnProperty("robotCommandList"),

      //   robotDetails[props.robotId].robotCommandList.length > 0
      // );
      destinationMarker.setLngLat([
        robotDetails[props.robotId].robotCommandList[0].longitude,
        robotDetails[props.robotId].robotCommandList[0].latitude,
      ]);
    }

    // move map
    // if (!map) return; // wait for map to initialize

    // console.log(map, "MAP");

    // if (map.getSource("robotPath")) {
    //   map.getSource("robotPath").setData({
    //     type: "Feature",
    //     properties: {},
    //     geometry: {
    //       type: "LineString",
    //       coordinates: newrobotPath,
    //     },
    //   });
    // }
    // console.log(robotDetails[props.robotId].robotCommandList[0].latitude)

    setnogozonescoordinates(
      props.nogozones.map((data) => [data.lng, data.lat])
    );
    setgeofencecoordinates(
      props.geofencezone.map((data) => [data.lng, data.lat])
    );
    let newpolygonarray = [];
    props.fleetzones.map((polygon, index) => {
      newpolygonarray.push(polygon.map((data) => [data.lng, data.lat]));
      return newpolygonarray;
    });
    setPolygonarray(newpolygonarray);

    // console.log(props.robotpathcheck);

    // if (!props.robotpathcheck) {
    //robot path from robot

    // Add a data source containing GeoJSON data.
    if (robotDetails[props.robotId] && robotDetails[props.robotId].path) {
      updateRobotpath();
    }
    // setRobotPath(newrobotPath);
    // console.log(robotPath);
    // console.log(robotPath)
    // map.on("load", () => {
    //   map.addSource("robotPath", {
    //     type: "geojson",
    //     data: {
    //       type: "Feature",
    //       geometry: {
    //         type: "LineString",
    //         // These coordinates outline Maine.
    //         coordinates: robotPath,
    //       },
    //     },
    //   });
    //   map.addLayer({
    //     id: "robotPathOutline",
    //     type: "line",
    //     source: "robotPath",
    // layout: {
    //   "line-join": "round",
    //   "line-cap": "round",
    // },
    // paint: {
    //   "line-color": "#0f0",
    //   "line-width": 4,
    // },
    //   });
    // });
    // } else if (props.robotpathcheck) {
    // console.log("INSIDE IF")

    // console.log(mapboxPath)
    //   map.on("load", () => {
    //     mapboxPath &&
    //       // Add a data source containing GeoJSON data.
    //       map.addSource("mapboxPath", {
    //         type: "geojson",
    //         data: {
    //           type: "Feature",
    //           properties: {},
    //           geometry: {
    //             type: "LineString",
    //             // These coordinates outline Maine.
    //             coordinates: mapboxPath,
    //           },
    //         },
    //       });

    //     map.addLayer({
    //       id: "mapboxPathOutline",
    //       type: "line",
    //       source: "mapboxPath",
    //       layout: {
    //         "line-join": "round",
    //         "line-cap": "round",
    //       },
    //       paint: {
    //         "line-color": "rgba(0,51,0,0.5)",
    //         "line-width": 4,
    //       },
    //     });
    //   });
    // }
    // }

    // return () => map.remove();
  }, [
    robotDetails[props.robotId].longitude,
    robotDetails[props.robotId].latitude,
    robotMarker,
    destinationMarker,
    props.robotId,
    props.fleetzones,
    props.geofencezone,
    props.nogozones,
    props.robotpathcheck,
    dispatch,
    robotDirections,
    robotDetails,
    robotDetails[props.robotId],
    robotDetails[props.robotId].path,
    // robotPath
  ]);
  const markerClicked = (title) => {
    window.alert(title);
  };

  // const robotId = props.robotId;
  // const toggleDirections, setToggleDirections = useState(props.toggleDirections)

  return (
    <>
      <div style={{ height: "100%" }}>
        <div
          className="sidebar"
          style={{
            backgroundColor: "rgba(35, 55, 75, 0.9)",
            color: "#fff",
            padding: "6px 12px",
            fontFamily: "monospace",
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            margin: "12px",
            borderRadius: "4px",
          }}
        >
          Ottobot Position [{robotDetails[props.robotId].latitude},{" "}
          {robotDetails[props.robotId].longitude}] | Zoom: {zoom}
        </div>
        <div
          id="map-container-id"
          // ref={mapContainer}
          className="map-container"
          style={{ height: "100%" }}
        />
      </div>
      {props.robotpathcheck &&
        robotDetails[props.robotId].hasOwnProperty("robotCommandList") &&
        robotDetails[props.robotId].robotCommandList.length > 0 &&
        robotDetails[props.robotId].robotCommandList[0].actionType ===
          "MOVELOCATION" && (
          <>
            {/* {checkActionId(
              robotDetails[props.robotId].robotCommandList[0],
              props
            ) && (
              <MapboxCon
              dispatch={dispatch}
                robotId={props.robotId}
                robotlatitude={props.robotlatitude}
                robotlongitude={props.robotlongitude}
                item={
                  robotDetails[props.robotId] &&
                  robotDetails[props.robotId].robotCommandList[0]
                }
                robotDetails={robotDetails}
              />
            )} */}
          </>
        )}
    </>
  );
};
export default React.memo(MapboxMain);
