import L from 'leaflet';

const robotIcon = new L.Icon({
    iconUrl: '/assets/images/map-robot-icon.svg',
    iconRetinaUrl:'/assets/images/map-robot-icon.svg',
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 30),
});


export { robotIcon };