import React from 'react'
import { UpdateRobotAction } from '../API';
import {toast } from 'react-toastify'
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useEffect } from 'react';

function RobotActionModal(props) {
   
    const UpdateRobotError=()=> toast.error('Robot Update Command  Failed',{
        position: toast.POSITION.BOTTOM_RIGHT
    })
    const UpdateRobotSuccess=()=>toast.success('Robot Update Command Success',{
        position: toast.POSITION.BOTTOM_RIGHT
    })
    const token=localStorage.getItem('token')
    const [finishbtn,setfinishbtn] =useState(true)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 750,
        bgcolor: '#282F42',
        boxShadow: 24,
        p: 0,
        borderRadius:'10px'
    }; 

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = (reason) => {
      if (reason && reason == "backdropClick") 
          return;
       setOpen(false)
   }
   const handlerobotAction=(status,actionId,orderId)=>{
        if(finishbtn){
             UpdateRobotAction(props.robotId,token, status,actionId, orderId)
             .then((res)=>{
             UpdateRobotSuccess()
             setfinishbtn(false)
             })
             .catch((err)=>{
              UpdateRobotError()    
             }) 
          }
    }  
  return (
    <div>
           {finishbtn ? <button className="RobotActions-Start-btn" onClick={()=>{handleOpen()}}>{props.name} Action</button> :<button className="RobotActions-Start-btn-disabled" disabled> {props.name} Action</button> } 
               {(()=>{
                      setInterval(()=>{
                      setfinishbtn(true)
                   }, 10000)
                })()}
         <Modal
        open={open}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <Box sx={style} >
              <div className='Cancel-order-Modal-Mainwrapper'>
                {/* <img src="/assets/images/cancel-order.png"/> */}
                <p> Do you want to do the </p>
                 <h5> "{props.name} action" ? </h5>
               
                   {props.commandlistdata.actionPriority===1 ? 
                    <div className="RobotActions_popup_content_wrapper"> 
                        <div>
                          <p> ORDER ID </p>
                          <h4>{props.commandlistdata.orderId} </h4>
                        </div>
                       <div>
                          <p> LOCATION </p>
                          {props.commandlistdata.latitude ? <h4> {props.commandlistdata.latitude} , {props.commandlistdata.longitude}</h4> : <h4>{props.commandlistdata.locationName}</h4>}  
                       </div>
                       <div>
                          <p> COMPARTEMENT </p>  
                          <h4>{props.commandlistdata.boxId} </h4> 
                       </div> 
                  </div>  :null } 
               
               <div className="Modal-button-Main-wrapper">
                <Button className="Modal-button-Main-btn" onClick={handleClose}> No</Button>
                <Button className="Modal-button-Main-btn yes-red-active" onClick={()=>{handlerobotAction(props.number,props.actionId,props.orderId)
                                                                                        handleClose() }}> Yes</Button>
               </div>
           </div> 
        </Box>
      </Modal>
    </div>
  )
}

export default RobotActionModal
