import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-bootstrap';
import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './redux/store';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Provider store={store}>
         <App />
         <ToastContainer />
      </Provider>
    </BrowserRouter>
);
