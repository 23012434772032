import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFleetList,
  getFleetListAdmin,
  getFleetListUser,
  robotListfleetId,
} from "../API/index";
import {
  FleetData,
  fleetlatitudeaction,
  fleetlogitudeaction,
  robotDataAction,
  storeRobotDetails,
} from "../redux/actions";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import Battery80Icon from "@mui/icons-material/Battery80";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Header from "./Header";
import IndividualRobot from "./IndividualRobot";
import $ from "jquery";
import ReactAudioPlayer from "react-audio-player";

function SelectFleet() {
  const dispatch = useDispatch();
  const [fleetlist, setfleetlist] = useState();
  const [robotmsg, setrobotmsg] = useState([]);
  const navigate = useNavigate();
  const email = localStorage.getItem("useremail");
  const isLogged = localStorage.getItem("Isloggedin");
  const token = localStorage.getItem("token");
  let audioaudio;

  const wholeData = useLocation();

  const robotMsgData = useSelector((state) => state.robotMessageReducer);
  const robotDetails = useSelector((state) => state.robotDetailsReducer);
  let teleop = [];
  let robotRunningMessages = [];
  const [showheading, setshowheading] = useState(false);
  const [headingflag, setheadingflag] = useState(false);

  const useremail = localStorage.getItem("useremail");

  useEffect(() => {
    if (
      token === "" ||
      token === "null" ||
      token === null ||
      token === undefined ||
      useremail === null ||
      useremail === "null" ||
      useremail === undefined
    ) {
      window.location.replace("/");
    } else {
      setfleetlist(wholeData?.state?.name?.fleet);
    }
    // teleop = [];
    // robotRunningMessages = [];
    setheadingflag(false);

    Object.keys(robotDetails)?.map((data) => {
      if (
        robotDetails[data].teleop === "YES" &&
        robotDetails[data].robotRunningState !== "TELE_OP_MOVING" &&
        robotDetails[data].robotRunningState !== "TELE_OP_OBSTACLE" &&
        robotDetails[data].robotRunningState !== "TELE_OP"
      ) {
        // console.log(robotDetails[data].teleop, data);
        setheadingflag(true);
        // console.log("why is this false", showheading);
        // return null;
      }
      if (setheadingflag) {
        setshowheading(true);
      }
    });

    // setTimeout(()=>{
    //  pausebeepsound()
    // }, 4000)
  }, [
    wholeData,
    robotMsgData,
    showheading,
    teleop,
    robotRunningMessages,
    robotDetails,
    token,
    useremail,
  ]);
  // useEffect(()=>{
  //   setInterval(()=>{
  //     window.location.reload()
  //   } , 10000)
  // })

  const robotlistRedux = useSelector((state) => state.robotlistReducer);
  const getrobotStatus = (item, data) => {
    switch (item.robotStatus) {
      case "AVAILABLE":
        return <p className="text-left m-0 p-color-green">Available</p>;
      case "UNAVAILABLE":
        return <p className="text-left m-0 p-color-red"> UnAvailable</p>;
      case "CHARGING":
        return <p className="text-left m-0 p-color-green">Charging</p>;
      case "ERROR":
        robotmsg.push({
          fleetId: item?.fleetId,
          fleetName: item?.fleetName,
          robotStatus: item.robotStatus,
          robotId: item,
          robotBattery: item.robotBattery,
          latitude: item?.latitude,
          longitude: item?.longitude,
          teleop: item.teleop,
        });
        return <p className="text-left m-0 p-color-red"> Error</p>;
      case "Need Help":
        robotmsg.push({
          fleetId: item?.fleetId,
          fleetName: item?.fleetName,
          robotStatus: item.robotStatus,
          robotId: item,
          robotBattery: item.robotBattery,
          latitude: item?.latitude,
          longitude: item?.longitude,
          teleop: item.teleop,
        });
        return <p className="text-left m-0 p-color-red"> Need Help</p>;
      default:
        return <p className="text-left m-0 p-color-red"> {item.robotStatus}</p>;
    }
  };
  const handlerobotdata = (robotdata) => {
    //  console.log('RobotDatarobotdata',robotdata)
    //  dispatch(robotDataAction(robotdata[0]))
    localStorage.setItem("fleetId", robotdata?.fleetId);
    navigate(`/dashboard/${robotdata?.robotId}`, {
      state: { name: robotdata },
    });
  };

  return (
    <>
      <Header />
      {/* {console.log('fleetlist',fleetlist)} */}
      <div className="container-fluid SelectFleetPage-wrapper-Main">
        {/* {console.log('wholeData',wholeData)}   */}
        <div>
          {headingflag && (
            <div>
              <h5 className="Select-Page-Robot-Message-Heading">
                {" "}
                ROBOT MESSAGE
              </h5>
            </div>
          )}
          <div className="SelectFleet-page-Error-box-wrapper">
            {headingflag && (
              <audio autoPlay={true} loop>
                <source
                  src="/static/media/alert.aa9b8554cbeaf7968e81.wav"
                  type="audio/wav"
                ></source>
              </audio>
            )}

            {Object.keys(robotDetails).map(
              (data, index) =>
                robotDetails[data].teleop === "YES" &&
                robotDetails[data].robotRunningState !== "TELE_OP_MOVING" &&
                robotDetails[data].robotRunningState !== "TELE_OP_OBSTACLE" &&
                robotDetails[data].robotRunningState !== "TELE_OP" && (
                  <div
                    key={"robot__detail_" + index}
                    className="SelectFleetPage-content-wrapper-Main border-red"
                    onClick={() => handlerobotdata(robotDetails[data])}
                  >
                    {/* <audio preload="auto" src="/static/media/alert.aa9b8554cbeaf7968e81.wav" autoplay="autoplay" loop id="playaudio"></audio> */}

                    <div className="border-blue-top SelectFleetPage-inner-li-div d-flex-center">
                      <button className="Error-state-teleop-ack-button">
                        {" "}
                        Teleop Ack
                      </button>
                      <div>
                        <div>
                          <h4 className="Select-page-fleetName">
                            {" "}
                            {robotDetails[data].fleetName}
                          </h4>
                        </div>
                        <div>
                          <p
                            className="SelectFleetPage-inner-li-div-robot-id"
                            style={{ color: "white" }}
                          >
                            {data}
                          </p>
                          {getrobotStatus(robotDetails[data], null)}
                        </div>
                      </div>
                      {/* <ReactAudioPlayer src="/static/media/alert.aa9b8554cbeaf7968e81.wav"
                                autoPlay
                                controls
                                ref={(element) => { audioaudio = element; }} /> */}
                      <div className="dashboard-left-side-div-one-inner-div">
                        <div className="dashboard-left-side-div-one-wrapper-battery-div">
                          <div className="battery-icon-css">
                            <span
                              style={{
                                width: "30px",
                                border: "1px solid #fff",
                                borderRadius: "4px",
                                padding: "2px",
                                height: "14px",
                                display: "inline-block",
                              }}
                            >
                              <strong
                                style={{
                                  background:
                                    parseInt(
                                      robotDetails[data].robotBattery
                                    ).toFixed(0) <= 20
                                      ? "#E4261C"
                                      : "rgb(113, 207, 98)",
                                  width: `${parseInt(
                                    robotDetails[data].robotBattery
                                  ).toFixed(0)}%`,
                                  display: "block",
                                  height: "8px",
                                }}
                              ></strong>
                            </span>
                          </div>
                          <p style={{ fontSize: "10px" }}>
                            {parseInt(robotDetails[data].robotBattery).toFixed(
                              0
                            )}
                            %
                          </p>
                        </div>
                        <span
                          style={{
                            background: "#fff",
                            width: "3px",
                            height: "5px",
                            display: "inline-block",
                            borderRadius: "3px",
                            marginRight: "3px",
                          }}
                        ></span>
                        <span
                          style={{
                            background: "#fff",
                            width: "3px",
                            height: "10px",
                            display: "inline-block",
                            borderRadius: "3px",
                            marginRight: "3px",
                          }}
                        ></span>
                        <span
                          style={{
                            background: "#fff",
                            width: "3px",
                            height: "15px",
                            display: "inline-block",
                            borderRadius: "3px",
                            marginRight: "3px",
                          }}
                        ></span>
                        <span
                          style={{
                            background: "#fff",
                            width: "3px",
                            height: "20px",
                            display: "inline-block",
                            borderRadius: "3px",
                            marginRight: "3px",
                            opacity: "0.5",
                          }}
                        ></span>
                        <span
                          style={{
                            background: "#fff",
                            width: "3px",
                            height: "25px",
                            display: "inline-block",
                            borderRadius: "3px",
                            marginRight: "3px",
                            opacity: "0.5",
                          }}
                        ></span>
                        {/* <SignalCellularAltIcon style={{color:'white' , margin:'7px', marginTop:'0px'}}/> */}
                        {data.robotStatus === "AVAILABLE" ? (
                          <PauseCircleIcon className="play-icon p-color-green" />
                        ) : (
                          <PlayCircleIcon className="play-icon p-color-red" />
                        )}
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
        <div className="SelectFleetPage-content-wrapper-Main">
          {fleetlist?.map((data, index) => {
            //  {console.log('fleetlist',fleetlist)}
            return (
              <div
                key={"robot__detail_" + index}
                style={{ width: "100%", marginTop: "20px" }}
              >
                <h3 className="Dashboard-content-div-one-left-side-h3">
                  {data.fleetName}
                </h3>
                <IndividualRobot
                  data={data}
                  latitude={data?.latitude}
                  longitude={data?.longitude}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default React.memo(SelectFleet);
