import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import * as yup from "yup";
// import { Form, Formik, useFormik } from "formik";
import {
  Button,
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getFleetListAdmin,
  getFleetListUser,
  getFleetListuser,
  login,
  robotListfleetId,
} from "../API/index";
import { storeRobotDetails, userlogin } from "../redux/actions";
import { useDispatch } from "react-redux";
// import { useAuth0 } from '@auth0/auth0-react';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let CryptoJS = require("crypto-js");
let Base64 = require("js-base64");

function Login() {
  // Login Form States
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginPasswordHide, setLoginPasswordHide] = useState(true);

  const loginSubmitHandler = (event) => {
    event.preventDefault();
    login({ email: loginEmail, password: loginPassword })
      .then((res) => {
        // console.log("Loginres", res.data.data);
        // console.log("Role", res.data.data.role);
        localStorage.setItem("useremail", loginEmail);
        localStorage.setItem("password", loginPassword);
        localStorage.setItem("Isloggedin", "Logintrue");
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("role", res.data.data.role[0]);
        // if(res.data.data.fleetId[0])
        // localStorage.setItem('fleetID', res.data.data.fleetId[0])
        notifySuccess();
        if (res.data.data.role[0] === "admin") {
          getFleetListAdmin(loginEmail, res.data.data.token)
            .then((res) => {
              navigate(`/selectfleet/${loginEmail}`, {
                state: { name: res?.data },
              });
            })
            .catch((err) => {
              // console.log('Error',err)
            });
        } else {
          getFleetListUser(loginEmail, res.data.data.token)
            .then((res) => {
              //  console.log('Fleet res',res)
              res.data.fleet.length > 0
                ? navigate(`/selectfleet/${loginEmail}`, {
                    state: { name: res?.data },
                  })
                : navigate("/dashboard");
            })
            .catch((err) => {
              // console.log('Error',err)
            });
        }
        dispatch(userlogin({ email: loginEmail, password: loginPassword }));
      })
      .catch((err) => {
        // console.log('Error Login', err)
        notifyfialed();
      });
  };

  const notifyfialed = () =>
    toast.error("Please Enter Correct Credentials", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

  const notifySuccess = () =>
    toast.success("Login Success", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  //   const {
  //     isLoading,
  //     loginWithPopup,
  //     loginWithRedirect,
  //     error,
  //     user,
  //     logout,
  //     isAuthenticated
  //   } = useAuth0();

  const [searchParams, setSearchParams] = useSearchParams();

  var depcrptData = "";
  var autologinvar = searchParams.get("autologin");
  var token = localStorage.getItem("token");
  var email = localStorage.getItem("useremail");
  var role = localStorage.getItem("role");
  useEffect(() => {
    if (token) {
      if (role === "admin") {
        getFleetListAdmin(email, token)
          .then((res) => {
            navigate(`/selectfleet/${email}`, { state: { name: res?.data } });
          })
          .catch((err) => {
            // console.log('Error',err)
          });
      } else {
        getFleetListUser(email, token)
          .then((res) => {
            //  console.log('Fleet res',res)
            {
              res.data.fleet.length > 0
                ? navigate(`/selectfleet/${email}`, {
                    state: { name: res?.data },
                  })
                : navigate("/dashboard");
            }
          })
          .catch((err) => {
            // console.log('Error',err)
          });
      }
    }
  }, []);
  useEffect(() => {
    if (autologinvar) {
      try {
        depcrptData = decryptData(
          autologinvar,
          process.env.REACT_APP_SECRET_LOGIN_PASSPHRASE
        );
        autologin(depcrptData);
      } catch {
        navigate("/");
      }
    }
  }, []);

  const decryptData = (searchParams, secretKey) => {
    const base64Decrypted = Base64.decode(searchParams);
    const decrypted = CryptoJS.AES.decrypt(base64Decrypted, secretKey);
    return decrypted.toString(CryptoJS.enc.Utf8);
  };
  const autologin = () => {
    // console.log("depcrptData", JSON.parse(depcrptData));
    var values = JSON.parse(depcrptData);
    login(values)
      .then((res) => {
        localStorage.setItem("useremail", values.email);
        localStorage.setItem("password", values.password);
        localStorage.setItem("Isloggedin", "Logintrue");
        localStorage.setItem("token", res.data.data.token);
        // console.log('Login Response respone', res)

        dispatch(storeRobotDetails(res.data.data.fleetId[0]));
        let robotData = res.data.data.filter(
          (item) => item[0].robotId === values.robotId
        );
        if (robotData[0][0].robotId) {
          notifySuccess();
          setTimeout(() => {
            navigate(`/dashboard/${robotData[0][0].robotId}`, {
              state: { name: robotData[0][0] },
            });
          }, 2000);
        } else {
          notifyfialed();
        }

        dispatch(userlogin(values));
      })
      .catch((err) => {
        notifyfialed();
      });
  };
  const isLogin = localStorage.getItem("token");

  const dispatch = useDispatch();
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .min(5, "Password should be of minimum 5 characters length")
      .required("Password is required"),
  });
  const navigate = useNavigate();
  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //     password: "",
  //     showPassword: true,
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values) => {
  //     login(values)
  //       .then((res) => {
  //         // console.log('Loginres', res.data.data)
  //         // console.log('Role',res.data.data.role)
  //         localStorage.setItem("useremail", values.email);
  //         localStorage.setItem("password", values.password);
  //         localStorage.setItem("Isloggedin", "Logintrue");
  //         localStorage.setItem("token", res.data.data.token);
  //         localStorage.setItem("role", res.data.data.role[0]);
  //         // if(res.data.data.fleetId[0])
  //         // localStorage.setItem('fleetID', res.data.data.fleetId[0])
  //         notifySuccess();
  //         if (res.data.data.role[0] === "admin") {
  //           getFleetListAdmin(values.email, res.data.data.token)
  //             .then((res) => {
  //               navigate(`/selectfleet/${values.email}`, {
  //                 state: { name: res?.data },
  //               });
  //             })
  //             .catch((err) => {
  //               // console.log('Error',err)
  //             });
  //         } else {
  //           getFleetListUser(values.email, res.data.data.token)
  //             .then((res) => {
  //               //  console.log('Fleet res',res)
  //               {
  //                 res.data.fleet.length > 0
  //                   ? navigate(`/selectfleet/${values.email}`, {
  //                       state: { name: res?.data },
  //                     })
  //                   : navigate("/dashboard");
  //               }
  //             })
  //             .catch((err) => {
  //               // console.log('Error',err)
  //             });
  //         }
  //         dispatch(userlogin(values));
  //       })
  //       .catch((err) => {
  //         // console.log('Error Login', err)
  //         notifyfialed();
  //       });
  //   },
  // });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="DesktopMain-wrapper">
      <div className="Login_page-wholeMain-wrapper">
        <div className="LoginPage-Main-wrapper">
          <form onSubmit={loginSubmitHandler} className="Login-page-form">
            <img
              src="/assets/images/Ottonomy Logo_White_With TM-02.png"
              className="Login-page-form-logo-image"
              alt="logo"
            />
            {/* <p className="Login-form-p">Login To dashboard</p>
          <h6>Enter you credentials below </h6> */}
            <div className="Loginpage=-main-form">
              <div className="Loginpage-main-form-inner-div">
                <TextField
                  id="standard-size-normal"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon
                          style={{
                            color: "rgb(189,196,224)",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={loginEmail}
                  name="email"
                  onChange={(event) => setLoginEmail(event.target.value)}
                  placeholder="Email ID"
                />
              </div>

              <div className="Loginpage-main-form-inner-div">
                <TextField
                  sx={{ borderRadius: "0px", width: "100%" }}
                  fullWidth
                  id="password"
                  variant="standard"
                  type={`${loginPasswordHide ? "password" : "text"}`}
                  placeholder="Password"
                  value={loginPassword}
                  onChange={(event) => setLoginPassword(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon
                          style={{
                            color: "rgb(189,196,224)",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setLoginPasswordHide(!loginPasswordHide)}
                        edge="end"
                        style={{ color: "rgb(189,196,224)" }}
                      >
                        {loginPasswordHide ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </div>
              {/* <div className="Loginpage-main-form-inner-div d-flex-start" style={{marginTop:'30px', textAlign:'start', marginLeft:'15px'}}> 
             
             <input type="checkbox" className='Login-page-remember-checkbox'/>
             <p className='Login-page-remember-checkbox-span' >Remember me</p>
            </div>  */}
            </div>
            <div className="Loginpage-btn-wrapper">
              <button type="submit" className="Loginpage-btn-wrapper-btn">
                {" "}
                Login
              </button>
            </div>
          </form>
        </div>
        {/* {isAuthenticated && 
    (  <>
        {JSON.stringify(user,null,2)}
        </>
     )}
     */}
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
