import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getFleetListAdmin, getFleetListUser } from "./API";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import SelectFleet from "./components/SelectFleet";
import "./App.css";

let CryptoJS = require("crypto-js");
let Base64 = require("js-base64");

function App() {
  let token = localStorage.getItem("token");
  let email = localStorage.getItem("useremail");
  let role = localStorage.getItem("role");
  const navigate = useNavigate();

  let data = {
    email: "info@pringleapi.com",
    password: "pringlesoft@ottonomy",
    robotId: "OB02221009",
  };

  const encryptData = (data, secretKey) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
    const base64Encrypted = Base64.encode(encrypted.toString());
    return base64Encrypted;
  };

  useEffect(() => {
    const encryptedData = encryptData(
      data,
      process.env.REACT_APP_SECRET_LOGIN_PASSPHRASE
    );
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path={"dashboard/:id"} element={<Dashboard />} />
        <Route path={"/:encrypt"} element={<Login />} />
        <Route path={"selectfleet/:email"} element={<SelectFleet />} />
      </Routes>
    </div>
  );
}

export default App;
