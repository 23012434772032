import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { robotListfleetId } from "../API";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import Battery80Icon from "@mui/icons-material/Battery80";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useDispatch, useSelector } from "react-redux";
import {
  robotlistdataAction,
  robotmsgAction,
  storeRobotDetails,
} from "../redux/actions";
import ringer from "./alert.wav";
import io from "socket.io-client";

function IndividualRobot(props) {
  const audioRef = useRef(null);
  const dispatch = useDispatch();
  const [robotlist, setrobotlist] = useState();
  let robotmsg = [];
  let teleopsound = [];
  let fleedId = props.data.fleetId;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const audio = new Audio(ringer);

  const updateState = useCallback(async () => {
    dispatch(storeRobotDetails(fleedId));
  }, [dispatch, fleedId]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 2000);

  const robotDetails = useSelector((state) => state.robotDetailsReducer);

  const [isCheck, setisCheck] = useState(true);

  const handlerobotdata = (robotdata) => {
    // console.log('RobotDataRobotData',robotdata)
    //  dispatch(robotDataAction(robotdata[0]))
    //  console.log('RobotDataRobotData',robotdata)
    localStorage.setItem("fleetId", robotdata.fleetId);
    navigate(`/dashboard/${robotdata.robotId}`, {
      state: {
        name: robotdata,
        latitude: props.latitude,
        longitude: props.longitude,
      },
    });
  };

  const playbeepsound = () => {
    function play() {
      audio.play();
      audio.loop = true;
      audio.muted = false;
      audio.id = "playaudio";
    }
    play();
    // console.log('audioaudio',audio)
  };
  let arraycontainsturtles = false;
  useEffect(() => {
    robotlist?.map((itm) => {
      if (itm[0].teleop === "YES") {
        teleopsound.push(itm[0].teleop);
        // playbeepsound()
      }
    });
    // arraycontainsturtles = (teleopsound.indexOf("YES") > -1);
    // if(arraycontainsturtles===true){
    //         playbeepsound()

    // }
  });
  const getTeleopStatus = (item, data) => {
    switch (item?.teleop) {
      case "YES":
        // playbeepsound()
        robotmsg.push({
          fleetId: data?.fleetId,
          fleetName: data?.fleetName,
          robotStatus: item.robotStatus,
          robotId: item.robotId,
          robotBattery: item.robotBattery,
          latitude: data.latitude,
          longitude: data.longitude,
          teleop: item.teleop,
        });
        if (isCheck) {
          setInterval(() => {
            dispatch(robotmsgAction(robotmsg));
            setisCheck(false);
          }, 4000);
        }
      case "No":

      default:
        audio.loop = false;
        return null;
    }
  };
  const getrobotStatus = (item, data) => {
    switch (item?.robotStatus) {
      case "AVAILABLE":
        return (
          <>
            <span className="text-left m-0 p-color-green">Available </span>{" "}
            <span
              style={{
                color: "#f7ca28",
                fontSize: "12px",
                textTransform: "lowercase",
              }}
            >
              ({item.robotRunningState.split("_").join(" ")})
            </span>
          </>
        );
      case "UNAVAILABLE":
        return (
          <>
            <span className="text-left m-0 p-color-red"> UnAvailable </span>{" "}
            <span
              style={{
                color: "#f7ca28",
                fontSize: "12px",
                textTransform: "lowercase",
              }}
            >
              ({item.robotRunningState.split("_").join(" ")})
            </span>
          </>
        );
      case "CHARGING":
        return (
          <>
            <span className="text-left m-0 p-color-green">Charging </span>
            <span
              style={{
                color: "#f7ca28",
                fontSize: "12px",
                textTransform: "lowercase",
              }}
            >
              ({item.robotRunningState.split("_").join(" ")})
            </span>
          </>
        );
      case "ERROR":
        // playbeepsound()
        robotmsg.push({
          fleetId: data?.fleetId,
          fleetName: data?.fleetName,
          robotStatus: item.robotStatus,
          robotId: item.robotId,
          robotBattery: item.robotBattery,
          latitude: data?.latitude,
          longitude: data?.longitude,
          teleop: item.teleop,
        });
        if (isCheck) {
          setTimeout(() => {
            dispatch(robotmsgAction(robotmsg));
            setisCheck(false);
          }, 4000);
        }
        return <p className="text-left m-0 p-color-red"> Error</p>;
      default:
        return (
          <p className="text-left m-0 p-color-red"> {item?.robotStatus}</p>
        );
    }
  };

  return (
    <>
      {Object.keys(robotDetails)?.length > 0 ? (
        <div className="SelectPage-robotList-wrapper">
          {Object.keys(robotDetails)?.map((data, index) => {
            return (
              <div
                key={"robot__" + index}
                className="border-blue-top SelectFleetPage-inner-li-div d-flex-center"
                onClick={() => handlerobotdata(robotDetails[data])}
              >
                {/* <audio preload="auto" src="/static/media/alert.aa9b8554cbeaf7968e81.wav" autoplay="autoplay" loop id="playaudio"></audio> */}
                <div>
                  <p
                    className="SelectFleetPage-inner-li-div-robot-id"
                    style={{ color: "white" }}
                  >
                    {" "}
                    {robotDetails[data]?.robotId}
                  </p>
                  {getrobotStatus(robotDetails[data], props.data)}
                  {getTeleopStatus(robotDetails[data], props.data)}
                </div>
                <div className="dashboard-left-side-div-one-inner-div">
                  <div className="dashboard-left-side-div-one-wrapper-battery-div">
                    <div className="battery-icon-css">
                      <span
                        style={{
                          width: "30px",
                          border: "1px solid #fff",
                          borderRadius: "4px",
                          padding: "2px",
                          height: "14px",
                          display: "inline-block",
                        }}
                      >
                        <strong
                          style={{
                            background:
                              parseInt(
                                robotDetails[data]?.robotBattery
                              ).toFixed(0) <= 20
                                ? "#E4261C"
                                : "rgb(113, 207, 98)",
                            width: `${parseInt(
                              robotDetails[data]?.robotBattery
                            ).toFixed(0)}%`,
                            display: "block",
                            height: "8px",
                          }}
                        ></strong>
                      </span>
                    </div>
                    {/* {console.log('robotDetails[data]?.robotBattery',robotDetails[data]?.robotBattery)}         */}
                    <p>
                      {parseInt(robotDetails[data]?.robotBattery).toFixed(0)}%
                    </p>
                  </div>
                  {/* <SignalCellularAltIcon style={{color:'white' , margin:'7px', marginTop:'0px'}}/> */}
                  <span
                    style={{
                      background: "#fff",
                      width: "3px",
                      height: "5px",
                      display: "inline-block",
                      borderRadius: "3px",
                      marginRight: "3px",
                    }}
                  ></span>
                  <span
                    style={{
                      background: "#fff",
                      width: "3px",
                      height: "10px",
                      display: "inline-block",
                      borderRadius: "3px",
                      marginRight: "3px",
                    }}
                  ></span>
                  <span
                    style={{
                      background: "#fff",
                      width: "3px",
                      height: "15px",
                      display: "inline-block",
                      borderRadius: "3px",
                      marginRight: "3px",
                    }}
                  ></span>
                  <span
                    style={{
                      background: "#fff",
                      width: "3px",
                      height: "20px",
                      display: "inline-block",
                      borderRadius: "3px",
                      marginRight: "3px",
                      opacity: "0.5",
                    }}
                  ></span>
                  <span
                    style={{
                      background: "#fff",
                      width: "3px",
                      height: "25px",
                      display: "inline-block",
                      borderRadius: "3px",
                      marginRight: "3px",
                      opacity: "0.5",
                    }}
                  ></span>
                  {robotDetails[data]?.robotStatus === "AVAILABLE" ? (
                    <PauseCircleIcon className="play-icon p-color-green" />
                  ) : (
                    <PlayCircleIcon className="play-icon p-color-red" />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="SelectPage-robotList-wrapper">
          <h4 className="No-robots-available"> No Robots Available</h4>
        </div>
      )}
    </>
  );
}

export default React.memo(IndividualRobot);
