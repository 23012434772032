import React, { useEffect, useRef, useState } from "react";
import {
  getMapLocationList,
  getrobotStatus,
  MapDetails,
  robotListfleetId,
} from "../API/index";
import {
  Circle,
  ImageOverlay,
  LayerGroup,
  MapContainer,
  Marker,
  Popup,
  SVGOverlay,
  TileLayer,
  useMap,
} from "react-leaflet";
import { CRS } from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import { RobotIdAction } from "../redux/actions";
import { useDispatch } from "react-redux";
import $ from "jquery";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
const L = window["L"];

function Maps(props) {
  const bounds = [
    [-3000, -3000],
    [3000, 3000],
  ];
  const token = localStorage.getItem("token");
  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [24, 36],
    iconAnchor: [13, 36],
  });
  const iconPerson = new L.Icon({
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(60, 75),
    className: "leaflet-div-icon",
  });

  const [mapUrl, setmapurl] = useState();
  var MapId = "";
  const [XCoordinate, setXCoordinate] = useState(0);
  const [YCoordinate, setYCoordinate] = useState(0);
  const [baseUrl, setbaseUrl] = useState();
  const [locationList, getLocationList] = useState();
  var robotlist = [];
  const [mapWidth, setmapwidth] = useState(0);
  const [mapHeight, setmapHeight] = useState(0);

  const fleetId = localStorage.getItem("fleetId");
  const [imagestate, setimagestate] = useState({
    backgroundImage: `url(${baseUrl})`,
    backgroundPosition: "0% 0%",
  });
  const robotsid = useSelector((state) => state.robotidReducer);
  let mapWi = "";
  let mapHe = "";
  const dispatch = useDispatch();
  useEffect(() => {
    setInterval(() => {
      robotListfleetId(props.fleetId, token)
        .then((res) => {
          createmap(locationList, res.data.data);
          // robotlist.push(robolist)

          // console.log('RobotStatusFleetId', res.data.data)
          // console.log('RobotListRobotlistRobotStatusFleetId',robotlist)
          dispatch(RobotIdAction(res.data.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }, 6000);
  }, [locationList]);

  const createmap = (locationList, robotlist) => {
    // console.log('locationListlocationList',locationList,robotlist)
    const xSize = mapWidth;
    const ySize = mapHeight;
    const margin = 20;
    const xMax = xSize - margin * 2;
    const yMax = ySize - margin * 2;

    // Create Random Points
    var data = [];
    robotlist[0]?.map((item) => {
      locationList?.push({
        metricPositionX: item.metricPositionX,
        metricPositionY: item.metricPositionY,
        homeRobot: null,
        imageLocationUrl: null,
        locationId: null,
        locationName: null,
        locationType: "ROBOT",
        mapId: null,
        metricPositionYaw: item.metricPositionYaw,
        positionX: item.positionX,
        positionY: item.positionY,
        positionYaw: item.positionYaw,
        status: null,
        wanderLoc: 0,
      });
    });
    data?.push(locationList);
    //  console.log('datavvvvv',data)
    // for (let i = 0; i < locationList?.length; i++) {
    //   data.push([Math.random() * xMax, Math.random() * yMax]);
    // }

    // Append SVG Object to the Page
    const svg = d3
      .select("#myPlot")
      .append("svg")
      // .append("g")
      .attr("transform", "translate(" + margin + "," + margin + ")");
    // .attr('width',`${mapWidth/4}`)
    // .attr('height',`${mapHeight/4}`)

    // X Axis
    const x = d3.scaleLinear().domain([0, mapWidth]).range([0, xMax]);

    svg
      .append("g")
      .attr("transform", "translate(0," + yMax + ")")
      .call(d3.axisBottom(x));

    // Y Axis
    const y = d3
      .scaleLinear()
      .domain([0, mapHeight])
      .range([yMax - 30, 0]);

    svg
      // append("g")
      .call(d3.axisLeft(y));

    // Dots
    svg
      .append("g")
      .attr("transform", "translate(" + -15 + "," + -20 + ")")
      .selectAll("dot")
      .data(data[0])
      .enter()
      .append("image")
      .attr("xlink:href", function (d, i) {
        if (d.locationType === "PICKUP") {
          return "/assets/images/robot-icon-pickup_1.svg";
        } else if (d.locationType === "HOME") {
          return "/assets/images/robot-icon (1).svg";
        } else if (d.locationType === "ROBOT") {
          return "/assets/images/robot-icon-dropoff_1.svg";
        } else {
          return "/assets/images/robot-icon-dropoff_1.svg";
        }
      })

      .attr("width", 20)
      .attr("height", 20)
      .attr("x", function (d, i) {
        return parseInt(d?.positionX) / 10;
      })
      .attr("y", function (d, i) {
        return parseInt(d?.positionY) / 10;
      });
    // console.log('datadata',data)
  };
  useEffect(() => {
    let mapdata;

    // console.log('fleetIdfleetId',props.fleetId)
    //  setInterval(()=>{
    MapDetails(props.fleetId, token)
      .then((res) => {
        // console.log("Map Details Response", res);
        mapdata = res;
        setXCoordinate(mapdata.data.data.mapOrigionX);
        setYCoordinate(mapdata.data.data.mapOrigionY);
        setbaseUrl(mapdata.data.data.baseMapImageUrl);
        localStorage.setItem("mapid", mapdata.data.data.mapId);
        //  console.log('mapdata',mapdata,XCoordinate,baseUrl)
        let mapW = mapdata.data.data.mapWidth;

        setmapwidth(mapW);
        let mapH = mapdata.data.data.mapHeight;
        setmapHeight(mapH);
        setmapurl({ ...mapUrl, mapdata });
      })
      .catch((err) => {
        console.log("Error", err);
      });
    //  },2000)
    let mapID = localStorage.getItem("mapid");
    // setInterval(()=>{
    getMapLocationList(props.fleetId, token)
      .then((res) => {
        // console.log("Map Location List", res);
        getLocationList(res.data.data);
      })
      .catch((err) => {
        console.log("Error Location List", err);
      });
    // },2000)
    mapWi = parseInt(mapWidth);
    mapHe = parseInt(mapHeight);
  }, []);
  const position = [XCoordinate, YCoordinate];
  const mapRef = useRef();
  const [tankR, setTankR] = useState(0);
  const center = [51.505, -0.09];

  return (
    <>
      <div className="Map-Main-Whole-wrapper border-blue-top">
        <figure className="frame">
          <TransformWrapper sx={{ width: "100%", height: "100%" }}>
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <div className="tools">
                  <button onClick={() => zoomIn()}>+</button>
                  <button onClick={() => zoomOut()}>-</button>
                  <button onClick={() => resetTransform()}>Reset</button>
                </div>
                <TransformComponent sx={{ width: "100%", height: "100%" }}>
                  <svg
                    id="myPlot"
                    className="zoom"
                    style={{
                      width: `${mapWidth / 10}`,
                      height: `${mapHeight / 10}`,
                      backgroundImage: `url(${baseUrl})`,
                      backgroundSize: "contain",
                      backgroundPosition: "0% 0%",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></svg>
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </figure>
      </div>
    </>
  );
}

export default React.memo(Maps);
